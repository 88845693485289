import { useCallback } from "react"
import { useStore } from "@store/index"

const useMetaAccountLinkRedirect = () => {
  const {
    accountLinkingStore: { locationLoading, getMetaLink },
  } = useStore()
  let token = null
  const redirect = useCallback(
    async (state: string, redirectUrl: string) => {
      if (locationLoading) return null

      const result = await getMetaLink(state, redirectUrl)

      const url = result.data
      const match = url.match(/access_token=([^&]*)/)
      if (match) {
        // eslint-disable-next-line prefer-destructuring
        token = match[1]
      }

      if (result.status === "success") window.location.href = url

      return result
    },
    [getMetaLink, locationLoading]
  )

  return { redirect, token }
}

export default useMetaAccountLinkRedirect

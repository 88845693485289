import { useCallback } from "react"
import { useStore } from "@store/index"

const useMetaAccountLinkRedirect = () => {
  const {
    accountLinkingStore: { locationLoading, getMetaLink },
  } = useStore()

  const redirect = useCallback(
    async (state: string, redirectUrl: string) => {
      if (locationLoading) return null

      const result = await getMetaLink(state, redirectUrl)

      if (result.status === "success") window.location.href = result.data

      return result
    },
    [getMetaLink, locationLoading]
  )

  return { redirect }
}

export default useMetaAccountLinkRedirect

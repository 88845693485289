import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"

import Stack from "@components/ui/Stack/Stack"
import { Button, ButtonGroup } from "@components/ui/Button"
import Box from "@components/ui/Box/Box"
import Icon from "@components/ui/Icon/Icon"
import ButtonDatePicker from "@components/ui/DatePicker/ButtonDatePicker"
import IconButton from "@components/ui/Button/IconButton"
import Typography from "@components/ui/Typography/Typography"
import TextField from "@components/ui/TextField/TextField"
import useOptionSearchList from "@framework/prototypes/useOptionsSearchList"
import Chip from "@components/ui/Chip/Chip"
import { DateRangeOption } from "@components/ui/DatePicker/types"
import { useStore } from "@root/store"
import { apiDateFormatter } from "@services/utils"
import {
  AnalyticsFilter,
  creativeTypes,
  defaultMetrics,
  filterEqualityTypes,
  filterIncludesTypes,
  filterOptions,
  groupByObjectOptions,
  metricOptions,
} from "@framework/types/creativeAnalytics"
import {
  ADSAnalyticsGroupType,
  ReportResponse,
} from "@services/creative-analytics"
import { useAlert } from "react-alert"
import SimpleFilterSelect from "@components/ui/FiltersDropDown/SimpleFilterSelect"
import SimpleSelect from "@components/ui/DropDown/SimpleSelect"
import SimpleMultiselect from "@components/ui/DropDown/SimpleMultiselect"
import SimpleFiltersMultiselect from "@components/ui/FiltersDropDown/SimpleFiltersMultiselect"
import styles from "./ReportControlPanel.module.scss"

interface ReportControlPanelProps {
  reportData?: ReportResponse["data"] | null
  onAppliedFilters: (filters: AnalyticsFilter[][]) => void
  onMetricsFilter?: (metrics: string[]) => void
  onCreateReportOpen?: () => void
  onEditReportSaveOpen?: () => void
}

const filterOptionsArray = Object.entries(filterOptions).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
)

const filterOptionsMetricsArray = Object.keys(filterEqualityTypes)
const filterOptionsMainArray = Object.keys(filterIncludesTypes)
const creativeTypeOptionsArray = Object.keys(creativeTypes)

const metricsOptionsArray = Object.entries(metricOptions).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
)

const metricsSortByOptionsArray: { value: string; label: string }[] = []
Object.entries(metricOptions).forEach(([key, value]) => {
  metricsSortByOptionsArray.push({
    value: key,
    label: value,
  })
})

const groupByOptionsArray = Object.entries(groupByObjectOptions).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
)

const ReportControlPanel: React.FC<ReportControlPanelProps> = observer(
  ({
    onAppliedFilters,
    onMetricsFilter,
    onCreateReportOpen,
    onEditReportSaveOpen,
  }) => {
    const {
      accountStore: { accountId },
      creativeAnalyticsStore: {
        getADSCards,
        adsCardsData,
        reportData,
        isLoading,
      },
      analyticsFiltersStore: {
        datePickerOptions,
        allFilters,
        filtersStatus,
        setFiltersStatus,
        setAllFilters,
      },
    } = useStore()

    const alert = useAlert()

    const { view, groupingType, period } = allFilters
    const [showFilters, setShowFilters] = useState(false)
    const [showMetrics, setShowMetrics] = useState(false)
    const [showSortBy, setShowSortBy] = useState(false)
    const [showGroupBy, setShowGroupBy] = useState(false)

    const [campaignsList, setCampaignsList] = useState<string[]>([])
    const [adSetsList, setAdSetsList] = useState<string[]>([])
    const [filterValue, setFilterValue] = useState<string>("")
    const [filterOptionsMetrics, setFilterOptionsMetrics] = useState<string>("")
    const [filterOptionsMain, setFilterOptionsMain] = useState<string>("")
    const [metricSortByValue, setMetricSortByValue] = useState<string>("Cost")
    const [groupByValue, setGroupByValue] = useState<
      ADSAnalyticsGroupType | "None"
    >(
      groupByOptionsArray.find((opt) => opt.value === groupingType)
        ? groupingType
        : "None"
    )
    const [filterTextValue, setFilterTextValue] = useState<string>("")
    const [creativeTypeOptions, setCreativeTypeOptions] = useState<string[]>([])
    const [filterCampaignsValue, setFilterCampaignsValue] = useState<string[]>(
      []
    )
    const [filterAdsetsValue, setFilterAdsetsValue] = useState<string[]>([])
    const [metricOptionsValues, setMetricOptionsValues] =
      useState<string[]>(defaultMetrics)

    const [singleFilter, setSingleFilter] = useState<AnalyticsFilter[]>([])
    const [appliedFiltersStrings, setAppliedFiltersStrings] = useState<
      string[]
    >([])
    const [appliedFilters, setAppliedFilters] = useState<AnalyticsFilter[][]>(
      []
    )

    const metricsOptions = useOptionSearchList({
      list: metricsOptionsArray ?? [],
    })

    const metricsSortByOptions = useOptionSearchList({
      list: metricsSortByOptionsArray ?? [],
    })

    const groupByOptions = useOptionSearchList({
      list: groupByOptionsArray ?? [],
    })

    const filterOptions = useOptionSearchList({
      list:
        groupByValue === "None"
          ? filterOptionsArray ?? []
          : filterOptionsArray.filter(
              (it) =>
                it.value !== "campaigns" &&
                it.value !== "adSets" &&
                it.value !== "creativeType"
            ) ?? [],
    })

    const handleFilterValue = (value: string) => {
      setFilterValue(value)
      setSingleFilter([{ key: 1, value }])
      setFilterOptionsMain("")
      setFilterOptionsMetrics("")
      setCreativeTypeOptions([])
      setFilterCampaignsValue([])
      setFilterAdsetsValue([])
      setFilterTextValue("")
    }

    useEffect(() => {
      if (!accountId) return
      if (
        reportData?.settings.time_period.start_date &&
        reportData?.id !== 0 &&
        filtersStatus !== "reportEditing"
      ) {
        if (reportData.settings.time_period.range_type === "static") {
          getADSCards(accountId, {
            from: apiDateFormatter(
              new Date(reportData.settings.time_period.start_date)
            ),
            to: apiDateFormatter(
              new Date(reportData.settings.time_period.end_date)
            ),
          }).then((res) => {
            if (res) alert.error(res)
          })
        }
        if (reportData.settings.time_period.range_type === "relative") {
          const from = new Date()
          const to = new Date()
          switch (reportData.settings.time_period.range_value) {
            case "one_week":
              from.setDate(to.getDate() - 7)
              break
            case "two_weeks":
              from.setDate(to.getDate() - 14)
              break
            case "month":
              from.setDate(to.getDate() - 31)
              break
            default:
          }
          getADSCards(accountId, {
            from: apiDateFormatter(from),
            to: apiDateFormatter(to),
          }).then((res) => {
            if (res) alert.error(res)
          })
        }
      } else if (
        (accountId && !reportData) ||
        (accountId && reportData?.id === 0)
      ) {
        getADSCards(accountId, {
          from: apiDateFormatter(period?.range[0]),
          to: apiDateFormatter(period?.range[1]),
        }).then((res) => {
          if (res) alert.error(res)
        })
      }
    }, [accountId, reportData])

    useEffect(() => {}, [
      reportData,
      filterValue,
      filterOptionsMain,
      filterOptionsMetrics,
      creativeTypeOptions,
      filterAdsetsValue,
      filterCampaignsValue,
      metricOptionsValues,
      singleFilter,
      metricSortByValue,
      groupingType,
      allFilters,
    ])

    useEffect(() => {
      onAppliedFilters(appliedFilters)
    }, [appliedFilters])

    useEffect(() => {
      if (reportData) {
        const isWrongGroupingType = groupByOptionsArray.find(
          (opt) => opt.value === reportData.settings.group
        )
        if (groupingType !== "None" && !isWrongGroupingType)
          alert.error(`Wrong grouping type: [${groupingType}]`)

        if (reportData.settings.filters) {
          const result: string[] = []
          const res: AnalyticsFilter[][] = reportData?.settings.filters.map(
            (f) => [
              { key: 1, value: f.name },
              { key: 2, value: f.comparator },
              { key: 3, value: f.value },
            ]
          )
          res.forEach((filter) => {
            const f = filter.map((it) => it.value).join(" ")
            result.push(f)
          })
          setAppliedFiltersStrings([...result])
          setAppliedFilters([...res])
        } else {
          setAppliedFiltersStrings([])
          setAppliedFilters([])
        }
        if (reportData.settings.sort_option?.type) {
          setMetricSortByValue(`${reportData.settings.sort_option.type}`)
          setAllFilters({
            ...allFilters,
            sortByMetric: {
              value: reportData.settings.sort_option.type,
              order: reportData.settings.sort_option.order,
            },
          })
        }
        if (reportData.settings.metrics.length) {
          setMetricOptionsValues(reportData.settings.metrics)
        }
        if (reportData.settings.group) {
          const res = groupByOptionsArray.find(
            (opt) => opt.value === reportData.settings.group
          )
            ? reportData.settings.group
            : "None"
          setGroupByValue(res)
        }
      } else {
        setAppliedFiltersStrings([])
        setAppliedFilters([])
        setGroupByValue("None")
        setMetricOptionsValues(defaultMetrics)
        setMetricSortByValue("Cost")
      }
    }, [reportData, adsCardsData])

    useEffect(() => {
      if (onMetricsFilter) onMetricsFilter(metricOptionsValues)
    }, [metricOptionsValues])

    useEffect(() => {
      if (onMetricsFilter) onMetricsFilter(metricOptionsValues)
    }, [])

    useEffect(() => {
      setFilterValue("")
      setSingleFilter([])
      setFilterOptionsMain("")
      setFilterOptionsMetrics("")
      setCreativeTypeOptions([])
      setFilterCampaignsValue([])
      setFilterAdsetsValue([])
      setFilterTextValue("")
      setMetricSortByValue("Cost")
      setAllFilters({
        ...allFilters,
        sortByMetric: { value: "Cost", order: "desc" },
      })
    }, [])

    useEffect(() => {
      const campaigns: Set<string> = new Set()
      const adSets: Set<string> = new Set()
      if (adsCardsData.AdCreatives.length) {
        adsCardsData.AdCreatives.forEach((c) => {
          if (c.CampaignName) {
            campaigns.add(c.CampaignName)
          }
        })
        adsCardsData.AdCreatives.forEach((c) => {
          if (c.AdsetName) {
            adSets.add(c.AdsetName)
          }
        })
      }
      setCampaignsList(Array.from(campaigns))
      setAdSetsList(Array.from(adSets))
    }, [adsCardsData])

    const handleFilterOptionsMain = (value: string) => {
      setFilterOptionsMain((prevState) => {
        setSingleFilter((prev) => {
          let res = []
          if (prev.find((i) => i.key === 2)) {
            res = prev.filter((it) => it.value !== prevState)
            return [...res, { key: 2, value }]
          }
          return [...prev, { key: 2, value }]
        })
        return value
      })
    }

    const handleFilterOptionsMetrics = (value: string) => {
      setFilterOptionsMetrics((prevState) => {
        setSingleFilter((prev) => {
          let res = []
          if (prev.find((i) => i.key === 2)) {
            res = prev.filter((it) => it.value !== prevState)
            return [...res, { key: 2, value }]
          }
          return [...prev, { key: 2, value }]
        })
        return value
      })
    }

    const handleSortByMetricsOrder = (value: string, order: "asc" | "desc") => {
      setAllFilters({
        ...allFilters,
        sortByMetric: { value, order },
      })
      if (filtersStatus === "reportView") {
        setFiltersStatus("reportEditing")
      }
    }

    const handleSortByMetrics = (value: string) => {
      setMetricSortByValue(value)
      setAllFilters({
        ...allFilters,
        sortByMetric: { value, order: "desc" },
      })
      if (filtersStatus === "reportView") {
        setFiltersStatus("reportEditing")
      }
    }

    const handleGroupBy = (value: ADSAnalyticsGroupType | "None") => {
      if (filtersStatus === "reportView") {
        setFiltersStatus("reportEditing")
      }
      if (view === "table") {
        setAllFilters({ ...allFilters, view: "card", groupingType: value })
      } else {
        setAllFilters({ ...allFilters, groupingType: value })
      }
      setGroupByValue(value)
    }

    const handleThirdFilter = (value: string, type: string) => {
      let val: string = ""
      if (type === "creativeType") {
        val = value
        setCreativeTypeOptions((prevState) =>
          prevState.includes(value)
            ? prevState.filter((it) => it !== value)
            : [...prevState, value]
        )
        const typeValue = creativeTypes[value]
        setSingleFilter((prev) => {
          let res = []
          if (prev.find((i) => i.value === typeValue && i.key === 3)) {
            res = prev.filter((it) => it.value !== typeValue)
            return res
          }

          return [...prev, { key: 3, value: typeValue }]
        })
      }
      if (type === "adsetsType") {
        val = value
        setFilterAdsetsValue((prevState) =>
          prevState.includes(value)
            ? prevState.filter((it) => it !== value)
            : [...prevState, value]
        )
        setSingleFilter((prev) => {
          let res = []
          if (prev.find((i) => i.value === value && i.key === 3)) {
            res = prev.filter((it) => it.value !== value)
            return res
          }

          return [...prev, { key: 3, value: val }]
        })
      }
      if (type === "campaignType") {
        val = value
        setFilterCampaignsValue((prevState) =>
          prevState.includes(value)
            ? prevState.filter((it) => it !== value)
            : [...prevState, value]
        )
        setSingleFilter((prev) => {
          let res = []
          if (prev.find((i) => i.value === value && i.key === 3)) {
            res = prev.filter((it) => it.value !== value)
            return res
          }

          return [...prev, { key: 3, value: val }]
        })
      }
    }

    const handleFilterTextValue = (value: string) => {
      setFilterTextValue(value)
      setSingleFilter((prev) => {
        let res = []
        if (prev.findIndex((i) => i.key === 3)) {
          res = prev.filter((it) => it.key !== 3)
          return [...res, { key: 3, value }]
        }
        return [...prev, { key: 3, value }]
      })
    }

    const handleMetricsOptions = (value: string) => {
      if (filtersStatus === "reportView") {
        setFiltersStatus("reportEditing")
      }
      setMetricOptionsValues((prev) =>
        prev.includes(value)
          ? prev.filter((it) => it !== value)
          : [...prev, value]
      )
    }

    const handleApplyFilter = () => {
      if (singleFilter.length < 3) return
      const res = singleFilter
        .sort((a, b) => a.key - b.key)
        .map((it) => it.value)
        .join(" ")
      setAppliedFiltersStrings((prev) => [...prev, res])
      setAppliedFilters((prev) => [...prev, singleFilter.sort()])
      setShowFilters(false)
      if (filtersStatus === "reportView") {
        setFiltersStatus("reportEditing")
      }
      handleFilterValue("")
    }

    const handleCancelFilter = () => {
      handleFilterValue("")
      setShowFilters(false)
    }

    const handleRemoveFilter = (filter: string, index: number) => {
      setAppliedFiltersStrings((prev) => prev.filter((it) => it !== filter))
      setAppliedFilters((prev) => prev.filter((it, i) => i !== index))
      if (filtersStatus === "reportView") {
        setFiltersStatus("reportEditing")
      }
    }

    const handleSetPeriod = (period: DateRangeOption) => {
      if (filtersStatus === "reportView") {
        setFiltersStatus("reportEditing")
      }
      setAllFilters({ ...allFilters, period })
      if (!accountId) return
      getADSCards(accountId, {
        from: apiDateFormatter(period.range[0]),
        to: apiDateFormatter(period.range[1]),
      }).then((res) => {
        if (res) alert.error(res)
      })
    }

    const toggleFilters = () => {
      setShowFilters((prev) => {
        setShowMetrics(false)
        if (prev) {
          handleFilterValue("")
        }
        return !prev
      })
    }

    if (isLoading) return <div />

    return (
      <Box>
        <Stack
          className={styles.reportControlPanel}
          direction="row"
          gutter="8"
          justify="space-between"
        >
          <Stack direction="row" gutter="8">
            {/* <Button color="secondary" before={<Icon name="refresh" />}> */}
            {/*  Sync */}
            {/* </Button> */}
            <ButtonDatePicker
              range={period}
              onRangeChange={handleSetPeriod}
              rangeOptions={datePickerOptions}
              showDays={!!reportData}
            />
            <Button
              color="secondary"
              before={<Icon name="filter" />}
              onClick={toggleFilters}
              after={
                appliedFiltersStrings.length > 0 ? (
                  <span className={styles.filtersCountIcon}>
                    <Typography type="body2" color="light">
                      {appliedFiltersStrings.length}
                    </Typography>
                  </span>
                ) : null
              }
            >
              Filters
            </Button>
            <div className={styles.filterButton}>
              <Button
                color="secondary"
                before={<Icon name="stack" />}
                onClick={() => setShowGroupBy((prev) => !prev)}
                disabled={view === "table"}
              >
                {groupByValue === "None"
                  ? "Group"
                  : groupByObjectOptions[groupByValue]}
              </Button>
              {showGroupBy && (
                <div className={styles.groupByMenu}>
                  <SimpleFilterSelect
                    className={styles.select}
                    onSelect={(value) =>
                      handleGroupBy(value as ADSAnalyticsGroupType)
                    }
                    onClickOutside={() => setShowGroupBy(false)}
                    value={groupByValue}
                    {...groupByOptions}
                  />
                </div>
              )}
            </div>
            <div className={styles.filterButton}>
              <Button
                color="secondary"
                before={<Icon name="eye" />}
                onClick={() => setShowMetrics((prev) => !prev)}
              >
                Metrics
              </Button>
              {showMetrics && (
                <div className={styles.metricsMenu}>
                  <SimpleFiltersMultiselect
                    className={styles.select}
                    inputClassName={styles.selectInput}
                    withSearch
                    onSelect={handleMetricsOptions}
                    value={metricOptionsValues}
                    placeholder="Search metrics"
                    onClickOutside={() => setShowMetrics(false)}
                    {...metricsOptions}
                  />
                </div>
              )}
            </div>
            <div className={styles.filterButton}>
              <ButtonGroup>
                <IconButton
                  disabled={view === "table"}
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    handleSortByMetricsOrder(
                      metricSortByValue,
                      allFilters?.sortByMetric?.order === "asc" ? "desc" : "asc"
                    )
                  }
                >
                  <Icon
                    name="sort-desc"
                    rotateAngle={
                      allFilters?.sortByMetric?.order === "desc" ? 0 : 180
                    }
                  />
                </IconButton>
                <Button
                  color="secondary"
                  onClick={() => setShowSortBy((prev) => !prev)}
                  disabled={view === "table"}
                >
                  {metricSortByValue}
                </Button>
              </ButtonGroup>
              {showSortBy && (
                <div className={styles.sortByMenu}>
                  <SimpleFilterSelect
                    className={styles.select}
                    inputClassName={styles.selectInput}
                    withSearch
                    placeholder="Search metrics"
                    onSelect={handleSortByMetrics}
                    onClickOutside={() => setShowSortBy(false)}
                    value={metricSortByValue}
                    {...metricsSortByOptions}
                  />
                </div>
              )}
            </div>
          </Stack>
          <Stack direction="row" gutter="8" justify="flex-end">
            <ButtonGroup>
              <IconButton
                variant="contained"
                color={view === "card" ? "primary" : "secondary"}
                onClick={() => {
                  if (filtersStatus === "reportView") {
                    setFiltersStatus("reportEditing")
                  }
                  setAllFilters({ ...allFilters, view: "card" })
                }}
              >
                <Icon name="dashboard1" />
              </IconButton>
              <IconButton
                variant="contained"
                color={view === "table" ? "primary" : "secondary"}
                onClick={() => {
                  if (filtersStatus === "reportView") {
                    setFiltersStatus("reportEditing")
                  }
                  setShowGroupBy(false)
                  setAllFilters({ ...allFilters, view: "table" })
                }}
              >
                <Icon name="menu" />
              </IconButton>
            </ButtonGroup>
            {filtersStatus === "base" && (
              <Button
                color="primary"
                before={<Icon name="add" />}
                onClick={onCreateReportOpen}
              >
                Create report
              </Button>
            )}
            {(filtersStatus === "reportEditing" ||
              filtersStatus === "reportView") &&
              !reportData?.is_read_only && (
                <Button
                  color="primary"
                  disabled={filtersStatus === "reportView"}
                  onClick={onEditReportSaveOpen}
                >
                  Save changes
                </Button>
              )}
          </Stack>
        </Stack>

        {showFilters && (
          <div className={styles.filterMenu}>
            <div className={styles.filterMenuSelects}>
              <Typography type="body1">Where</Typography>
              <SimpleSelect
                className={styles.filterSelect}
                withSearch
                titleItems
                onSelect={handleFilterValue}
                value={filterValue}
                {...filterOptions}
              />
              {filterValue === "campaigns" ||
              filterValue === "adSets" ||
              filterValue === "creativeType" ? (
                <SimpleSelect
                  className={styles.filterSelect}
                  options={filterOptionsMainArray}
                  onSelect={handleFilterOptionsMain}
                  value={filterOptionsMain}
                  renderValue={(value) => filterIncludesTypes[value]}
                />
              ) : null}

              {filterValue !== "campaigns" &&
              filterValue !== "adSets" &&
              filterValue !== "creativeType" ? (
                <SimpleSelect
                  className={styles.filterSelect}
                  options={filterOptionsMetricsArray}
                  onSelect={handleFilterOptionsMetrics}
                  value={filterOptionsMetrics}
                  renderValue={(value) => filterEqualityTypes[value]}
                />
              ) : null}

              {filterValue !== "campaigns" &&
              filterValue !== "adSets" &&
              filterValue !== "creativeType" ? (
                <TextField
                  value={filterTextValue}
                  onChange={(e) => handleFilterTextValue(e.target.value)}
                  className={styles.filterSelect}
                  placeholder="Value"
                  type="number"
                  step="0.01"
                />
              ) : null}

              {filterValue === "creativeType" ? (
                <SimpleMultiselect
                  className={styles.filterSelect}
                  value={creativeTypeOptions}
                  onSelect={(value) => handleThirdFilter(value, "creativeType")}
                  options={creativeTypeOptionsArray}
                  renderValue={(value) => creativeTypes[value]}
                />
              ) : null}

              {filterValue === "campaigns" ? (
                <SimpleMultiselect
                  className={styles.filterSelect}
                  options={campaignsList}
                  onSelect={(value) => handleThirdFilter(value, "campaignType")}
                  value={filterCampaignsValue}
                />
              ) : null}
              {filterValue === "adSets" ? (
                <SimpleMultiselect
                  className={styles.filterSelect}
                  options={adSetsList}
                  onSelect={(value) => handleThirdFilter(value, "adsetsType")}
                  value={filterAdsetsValue}
                />
              ) : null}
            </div>

            <Stack direction="row" gutter="16" justify="flex-end">
              <Button color="secondary" onClick={handleCancelFilter}>
                Cancel
              </Button>

              <Button color="primary" onClick={handleApplyFilter}>
                Apply
              </Button>
            </Stack>
          </div>
        )}

        {appliedFiltersStrings.length ? (
          <div className={styles.filteredBy}>
            {appliedFiltersStrings.map((filter, index) => {
              if (!filter) return null
              const filtersStringArray = filter.split(" ")
              const metric =
                filtersStringArray[0].charAt(0).toUpperCase() +
                filtersStringArray[0].slice(1)
              const comparator =
                Object.entries(filterEqualityTypes).find(
                  ([key]) => key === filtersStringArray[1]
                )?.[1] ??
                Object.entries(filterIncludesTypes).find(
                  ([key]) => key === filtersStringArray[1]
                )?.[1]
              const value = filtersStringArray.slice(2).join(" ")
              return (
                <Chip
                  type="outlined"
                  color="primary"
                  endIcon={
                    <Icon
                      name="cross"
                      onClick={() => handleRemoveFilter(filter, index)}
                    />
                  }
                  className={styles.filterChip}
                  key={filter}
                >
                  {`${metric} ${comparator} ${value}`}
                </Chip>
              )
            })}
          </div>
        ) : null}
      </Box>
    )
  }
)

export default ReportControlPanel

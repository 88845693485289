import { action, computed, observable } from "mobx"
import { ID, Option } from "@framework/types/types"
import accountService from "@services/account.service"
import clientService from "@services/client.service"
import AccountStore from "../account/account"
import { channelOptions } from "./constants"

const DEFAULT_ERROR = "Unexpected error"

export type Channel = {
  iconSrc: string
} & Option

export type LinkedAccount = {
  channel: Channel
  accountName: string
  adAccountId: string
  status: string
  adoroAccountName: string
}

export type CustomerType = {
  AccountID: string
  AccountName: string
  Token: string
}

export type LinkedAccountKeys = keyof LinkedAccount

export class AccountLinkingStore {
  @observable locationLoading: boolean = false

  @observable customerListLoading: boolean = false

  @observable linkPending: boolean = false

  @observable customerList: CustomerType[] | null = null

  @observable customerError: string | null = null

  @observable accountStore: AccountStore | null = null

  @observable getLinkError: string | null = null

  constructor(accountStore: AccountStore) {
    this.accountStore = accountStore
  }

  @action getLink = async (redirectState: string) => {
    if (this.locationLoading) return { status: "canceled", message: "Busy" }
    try {
      this.locationLoading = true
      this.getLinkError = null

      const response = await accountService.getLinkAccountLocation(
        redirectState,
        true
      )
      if (typeof response.data.data !== "string")
        throw Error("Unexpected response")

      return { status: "success", data: response.data.data, message: "ok" }
    } catch (error: any) {
      this.getLinkError = "Unexpected error"
    } finally {
      this.locationLoading = false
    }
    return { status: "failed", message: this.getLinkError }
  }

  @action getMetaLink = async (state: string, redirectUrl: string) => {
    if (this.locationLoading) return { status: "canceled", message: "Busy" }
    try {
      this.locationLoading = true
      this.getLinkError = null

      const response = await accountService.getLinkMetaAccountLocation(
        state,
        redirectUrl
      )
      if (typeof response.data.data !== "string")
        throw Error("Unexpected response")

      return { status: "success", data: response.data.data, message: "ok" }
    } catch (error: any) {
      this.getLinkError = "Unexpected error"
    } finally {
      this.locationLoading = false
    }
    return { status: "failed", message: this.getLinkError }
  }

  @action loadCustomerList = async (
    code: string,
    state: number | string = "0",
    source?: "client" | "account"
  ) => {
    this.customerListLoading = true
    this.customerError = null
    try {
      const publicSource = source == null
      const response = await accountService.getCustomers(
        code,
        state,
        !publicSource ? source === "client" || undefined : false,
        publicSource
      )
      this.customerList = response.data.data ?? []
    } catch (error: any) {
      this.customerError = error.response?.data?.error ?? DEFAULT_ERROR
    } finally {
      this.customerListLoading = false
    }
    return this.customerError
  }

  @action linkAccount = async (accountId: number, item: CustomerType) => {
    this.linkPending = true
    this.customerError = null
    try {
      await accountService.linkAccount(
        accountId,
        item.Token,
        item.AccountID,
        item.AccountName
      )
    } catch (error: any) {
      this.customerError =
        error?.response?.data?.error ?? "Can't link this account"
    } finally {
      this.linkPending = false
    }
    return this.customerError
  }

  @action validateMetaAccountCredentials = async (
    accountId: ID,
    metaAccountId: string
  ) => {
    try {
      await accountService.validateMetaAccount(accountId, metaAccountId)
    } catch (error: any) {
      return error?.response?.data?.error ?? "Unexpected error"
    }
    return null
  }

  @action linkMetaAdsAccount = async (
    accountId: ID,
    metaAdsAccountName: string,
    metaAdsAccountId: number
  ) => {
    this.linkPending = true
    this.customerError = null
    try {
      await accountService.updateAccountInfo(accountId, {
        metaAdsAccountName,
        metaAdsAccountId,
      })
    } catch (error: any) {
      this.customerError = error?.response?.data?.error ?? "Unexpected error"
    } finally {
      this.linkPending = false
    }
    return this.customerError
  }

  @action linkClientToAccount = async (
    clientId: number,
    item: CustomerType
  ) => {
    this.linkPending = true
    this.customerError = null
    try {
      await clientService.linkAccount(
        clientId,
        item.Token,
        item.AccountID,
        item.AccountName
      )
    } catch (error: any) {
      this.customerError =
        error?.response?.data?.error ?? "Can't link this account"
    } finally {
      this.linkPending = false
    }
  }

  @computed get linkedGoogleAccount() {
    if (!this.accountStore?.account) return null
    const { adAccountId, name } = this.accountStore?.account
    if (adAccountId) {
      return {
        adAccountId,
        accountName: "",
        adoroAccountName: name,
        channel: channelOptions[0],
        status: "Connected",
      }
    }
    return null
  }

  @computed get linkedFacebookAccount() {
    if (!this.accountStore?.account?.metaCredentials) return null
    const { name, metaCredentials } = this.accountStore?.account
    if (metaCredentials.accountId) {
      return {
        adAccountId: metaCredentials.accountId.toString(),
        accountName: metaCredentials.accountName ?? "",
        adoroAccountName: name,
        channel: channelOptions[1],
        status: "Connected",
      }
    }
    return null
  }
}

export default AccountLinkingStore

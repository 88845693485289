import React, { useEffect, useState } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { useHistory } from "react-router-dom"

import { formatCustomerID } from "@utils/stringUtils"
import SelectAccountChannelModal from "@components/modals/SelectAccountChannelModal/SelectAccountChannelModal"
import Box from "@components/ui/Box/Box"
import Button from "@components/ui/Button/Button"
import ToggleButton from "@components/ui/CheckBox/ToggleButton/ToggleButton"
import Icon from "@components/ui/Icon/Icon"
import Loader from "@components/ui/Loader/Loader"
import NoData from "@components/ui/NoData/NoData"
import Typography from "@components/ui/Typography/Typography"
import useQueryParams from "@framework/hooks/useQueryParams"
import { mbNoData } from "@services/utils"
import { useStore } from "@store/index"
import {
  CustomerType,
  LinkedAccount,
  LinkedAccountKeys,
} from "@store/account-linking/account-linking"
import useAccountLinkRedirect from "@framework/prototypes/LinkAccount/useAccountLinkRedirect"
import SelectCustomerModal from "@components/modals/SelectCustomerModal/SelectCustomerModal"
import { LinkAccountCases } from "@framework/prototypes/LinkAccount/LinkAccountRedirect"
import useToggle from "@framework/hooks/useToggle"

import LinkFacebookAccountModal from "@components/modals/LinkFacebookAccountModal/LinkFacebookAccountModal"
import useMetaAccountLinkRedirect from "@framework/prototypes/LinkAccount/useMetaAccountLinkRedirect"
import styles from "./LinkedAccounts.module.scss"

type LinkedAccountsProps = {
  linkCase?: LinkAccountCases
  className?: string
}

const mapper = [
  { name: "channel", label: "Channel" },
  {
    name: "accountName",
    label: "Account name",
    renderCallback: (value: string) => mbNoData(value),
  },
  {
    name: "adAccountId",
    label: "Customer id",
  },
  { name: "status", label: "Status" },
  { name: "adoroAccountName", label: "Adoro Account Name" },
]

const formMapper = [
  { name: "channel", label: "Channel" },
  { name: "accountName", label: "Account name" },
  {
    name: "adAccountId",
    label: "Customer id",
  },
  { name: "status", label: "Status" },
  { name: "adoroAccountName", label: "" },
]

const LinkedAccounts: React.FC<LinkedAccountsProps> = observer(
  ({ className, linkCase = "account" }) => {
    const {
      manageProfileStore: { isEdit, setIsEdit },
      accountLinkingStore: {
        linkedGoogleAccount,
        linkedFacebookAccount,
        linkPending,
        linkAccount,
        loadCustomerList,
      },
      accountStore: { accountId, loading: accountLoading, loadAccount },
    } = useStore()

    const [code, setCode] = useState<string>()

    const customersModal = useToggle(false)
    const channelModal = useToggle(false)
    const connectMetaAccountModal = useToggle(false)

    const history = useHistory()
    const params = useQueryParams()

    const { redirect: linkRedirect } = useAccountLinkRedirect()
    const { redirect: linkMetaRedirect } = useMetaAccountLinkRedirect()

    const handleNewAccountClick = (item: string) => {
      if (!accountId) return

      if (item === "googleAds") {
        linkRedirect(linkCase, { id: accountId })
      }

      if (item === "facebookAds") {
        linkMetaRedirect("state", window.location.href)
      }

      channelModal.setOpened(false)
    }

    const handleSelectChannel = () => channelModal.setOpened(true)

    const finalizeMetaLinking = () => {
      connectMetaAccountModal.setOpened(false)
      loadAccount()
      setIsEdit(false)
    }

    const handleLinkModalClose = () => {
      customersModal.setOpened(false)
      loadAccount()
      setIsEdit(false)
    }

    const handleLinkAccount = async (selected: CustomerType) => {
      if (!accountId) return
      const error = await linkAccount(accountId, selected)
      if (error == null) customersModal.setOpened(false)
    }

    useEffect(() => {
      const { code } = params
      if (code) {
        setCode(code)
        history.replace(history.location.pathname)
      }
    }, [params])

    useEffect(() => {
      if (accountId && code) {
        setIsEdit(true)
        loadCustomerList(code, accountId, "account")
        customersModal.setOpened(true)
      }
    }, [code])

    useEffect(() => () => setIsEdit(false), [])

    return (
      <div className={clsx(styles.root, className)}>
        <Typography type="h2" className={styles.title}>
          Linked accounts
        </Typography>
        <Loader isLoading={accountLoading} />
        <div className={styles.container}>
          {linkedGoogleAccount || linkedFacebookAccount ? (
            isEdit ? (
              <>
                {linkedGoogleAccount != null && (
                  <RowForm
                    data={linkedGoogleAccount}
                    key={linkedGoogleAccount.adAccountId}
                  />
                )}

                {linkedFacebookAccount != null && (
                  <RowForm
                    data={linkedFacebookAccount}
                    key={linkedFacebookAccount.adAccountId}
                  />
                )}
              </>
            ) : (
              <>
                <div className={styles.row}>
                  {mapper.map(({ name, label }) => (
                    <Typography
                      type="subhead1"
                      color="gray"
                      upperCase
                      key={name}
                    >
                      {label}
                    </Typography>
                  ))}
                </div>

                {linkedGoogleAccount != null && (
                  <Row
                    data={linkedGoogleAccount}
                    key={linkedGoogleAccount.adAccountId}
                  />
                )}

                {linkedFacebookAccount != null && (
                  <Row
                    data={linkedFacebookAccount}
                    key={linkedFacebookAccount.adAccountId}
                  />
                )}
              </>
            )
          ) : (
            <NoData>None linked accounts added</NoData>
          )}
          {isEdit && (
            <Button
              onClick={handleSelectChannel}
              startIcon={{ name: "plus", circle: true }}
              className={styles.button}
            >
              Link Account
            </Button>
          )}
        </div>
        <SelectCustomerModal
          isOpen={customersModal.isOpened}
          isLoading={linkPending}
          onSubmit={handleLinkAccount}
          onClose={handleLinkModalClose}
        />

        {channelModal.isOpened && (
          <SelectAccountChannelModal
            isOpen
            onClose={() => channelModal.setOpened(false)}
            onSubmit={handleNewAccountClick}
          />
        )}

        {connectMetaAccountModal.isOpened && (
          <LinkFacebookAccountModal
            isOpen
            onClose={() => connectMetaAccountModal.setOpened(false)}
            onSubmit={finalizeMetaLinking}
          />
        )}
      </div>
    )
  }
)

type RowProps = {
  data: LinkedAccount
}

const Row: React.FC<RowProps> = ({ data }) => (
  <Box className={styles.row} padding="8px 6px 6px 6px">
    {mapper.map(({ name, renderCallback }) => {
      const item = data[name as LinkedAccountKeys]
      if (item === undefined) return null
      if (typeof item !== "string") {
        return (
          <Typography color="gray" className={styles.cell} key={name}>
            <img src={item.iconSrc} alt={item.label} className={styles.icon} />
            {item.label}
          </Typography>
        )
      }

      return (
        <Typography color="gray" className={styles.cell} key={name}>
          {renderCallback?.(item) ?? item}
        </Typography>
      )
    })}
  </Box>
)

const RowForm: React.FC<RowProps> = ({ data }) => (
  <Box className={styles.box}>
    <div className={styles.rowContainer}>
      {formMapper.map(({ label, name }) => (
        <Typography type="subhead1" color="gray" upperCase key={name}>
          {label}
        </Typography>
      ))}
    </div>
    <div className={styles.rowContainer}>
      <div style={{ zIndex: 1 }}>
        <Button variant="outlined">
          <span>{data.channel.label} Link</span>
          <div className={styles.iconWrapper}>
            <Icon name="done" />
          </div>
        </Button>
      </div>
      <Typography type="h4">{data.accountName || "-"}</Typography>
      <Typography type="h4">{data.adAccountId}</Typography>
      <div className={styles.status}>
        <Typography type="h4" color="primary">
          Connected
        </Typography>
        <ToggleButton checked />
      </div>
      <div style={{ zIndex: 1, justifySelf: "flex-end" }}>
        <Button disabled variant="outlined" startIcon={{ name: "trash" }}>
          <span>Delete</span>
        </Button>
      </div>
    </div>
  </Box>
)

export default LinkedAccounts
